<template>
  <v-container fluid>
    <v-snackbar :value="isError" color="error">
      {{ isError ? getError.errorMessage : '' }}
      <v-btn color="white" text @click="setError({ isError: false, error: null})">Cerrar</v-btn>
    </v-snackbar>
    <v-snackbar :value="infoSnackbar" color="success">
      {{ infoSnackbar ? infoSnackbarMessage : '' }}
      <v-btn color="white" text @click="infoSnackbar = false">Cerrar</v-btn>
    </v-snackbar>
    <v-dialog
      v-model="nuevaVentaCreditoDialog"
      fullscreen
      scrollable
      transition="dialog-bottom-transition"
    >
      <NuevaVenta
        @cerrarDialog="nuevaVentaCreditoDialog = false"
        @cerrarYActualizar="nuevaVentaActualizar"
      ></NuevaVenta>
    </v-dialog>
    <v-row no-gutters>
      <v-col>
        <v-data-table
          fixed-header
          :headers="headers"
          :items="ventas"
          :loading="isLoading"
          loading-text="Cargando... Espera un Momento"
          no-data-text="No hay datos para mostrar"
          :search="buscarTabla"
          item-key="idVenta"
          class="elevation-1"
          show-expand
          single-expand
          @item-expanded="whenExpanded"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <div>
                <v-toolbar-title>MIS VENTAS</v-toolbar-title>
                <v-toolbar-title class="subtitle-1" v-if="$vuetify.breakpoint.smAndDown">Crédito</v-toolbar-title>
              </div>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-toolbar-title class="subtitle-1" v-if="$vuetify.breakpoint.mdAndUp">Crédito</v-toolbar-title>
              <v-btn icon color="success" class="mx-2" @click="actualizarTabla">
                <v-icon>mdi-reload</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="success" dark @click="nuevaVentaCreditoDialog = true">
                Nueva Venta
                <v-icon right>mdi-plus-circle-outline</v-icon>
              </v-btn>
            </v-toolbar>
            <v-layout class="py-3 d-flex flex-column">
              <v-row>
                <v-col cols="12" sm="12" class="pb-0">
                  <v-text-field
                    v-model="buscarTabla"
                    prepend-inner-icon="mdi-magnify"
                    label="Buscar en la tabla..."
                    outlined
                    dense
                    class="mx-4 flex-shrink-0 flex-grow-1"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="4">
                  <v-dialog
                    ref="dialogFechaTablaInicio"
                    v-model="modalFechaTablaInicio"
                    :return-value.sync="fechaTablaInicio"
                    width="290px"
                    class="flex-shrink-0 flex-grow-0"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="computedFechaFormateadaInicio"
                        label="Fecha de Inicio"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-on="on"
                        outlined
                        dense
                        class="mx-4"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="fechaTablaInicio" scrollable locale="es">
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modalFechaTablaInicio = false">Cancelar</v-btn>
                      <v-btn text color="primary" @click="actualizarFechaInicio()">OK</v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-dialog
                    ref="dialogFechaTablaFin"
                    v-model="modalFechaTablaFin"
                    :return-value.sync="fechaTablaFin"
                    width="290px"
                    class="flex-shrink-0 flex-grow-0"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="computedFechaFormateadaFin"
                        label="Fecha Final"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-on="on"
                        outlined
                        dense
                        class="mx-4"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="fechaTablaFin" scrollable locale="es">
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modalFechaTablaFin = false">Cancelar</v-btn>
                      <v-btn text color="primary" @click="actualizarFechaFin()">OK</v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="4"
                  class="d-flex justify-end"
                  v-if="getInfoUsuario.rol != 'Usuario'"
                >
                  <v-btn
                    outlined
                    :dark="!!ventas.length"
                    :disabled="!ventas.length"
                    class="mx-4"
                    color="green"
                    @click="exportToExcel"
                  >
                    <v-icon left>mdi-file-excel</v-icon>Exportar
                  </v-btn>
                </v-col>
              </v-row>
            </v-layout>
          </template>
          <template v-slot:item.costoVentaTotal="{ item }">
            <v-chip :color="getEstado(item.costoVentaTotal)" dark>
              S/ {{
              item.costoVentaTotal.toFixed(2)
              }}
            </v-chip>
          </template>
          <template v-slot:item.totalContado="{ item }">
            S/ {{
            item.totalContado.toFixed(2)
            }}
          </template>
          <template v-slot:item.totalCredito="{ item }">
            S/ {{
            item.totalCredito.toFixed(2)
            }}
          </template>
          <template v-slot:item.saldoActual="{ item }">
            S/ {{
            item.saldoActual.toFixed(2)
            }}
          </template>
          <template v-slot:item.fechaVenta="{ item }">{{ formatDateTable(item.fechaVenta) }}</template>
          <template v-slot:expanded-item="{ headers, item }" style="width: 100%">
            <td :colspan="headers.length" v-if="expandedLoading" class="px-0">
              <v-skeleton-loader ref="expSkeleton" type="article" class="mx-auto"></v-skeleton-loader>
            </td>
            <td :colspan="headers.length" v-else class="px-0 grey lighten-4">
              <div v-if="Object.keys(detalleVenta).length">
                <v-container fluid>
                  <v-layout>
                    <v-row justify="center">
                      <v-col cols="12" sm="12" md="12" class="pb-0">
                        <div class="d-flex justify-space-around">
                          <p>
                            Productos Vendidos:
                            <span>{{item.cantidadVendidos}}</span>
                          </p>
                          <p>
                            Productos Prestados:
                            <span>{{item.cantidadPrestados}}</span>
                          </p>
                          <p>
                            Deuda Actual:
                            <span>S/ {{item.saldoActual}}</span>
                          </p>
                        </div>
                      </v-col>
                      <v-col cols="12" sm="12" md="6" class="pt-0">
                        <v-subheader>Lista de Productos</v-subheader>
                        <v-data-table
                          dense
                          :items="detalleVenta.productos"
                          :headers="headersListaProductos"
                          item-key="idProducto"
                          hide-default-footer
                        >
                          <template v-slot:item.costoUnitario="{ value }">S/ {{value.toFixed(2)}}</template>
                          <template
                            v-slot:item.costoTotalProducto="{ value }"
                          >S/ {{value.toFixed(2)}}</template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </v-layout>
                </v-container>
              </div>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NuevaVenta from '@/components/user/userNuevaVenta.vue'

import { mapActions, mapGetters, mapMutations } from 'vuex'

import XLSX from 'xlsx'

export default {
  components: {
    NuevaVenta
  },

  data: vm => ({
    verificado: true,
    headers: [
      { text: 'Venta #', value: 'idVenta', align: 'center' },
      {
        text: 'Vendedor',
        value: 'nombreVendedor',
        align: 'center',
        width: '10%'
      },
      { text: 'Cliente', value: 'nombreCliente', align: 'center' },
      {
        text: '# de Items Vendidos',
        value: 'cantidadVendidos',
        align: 'center',
        width: '5%'
      },
      {
        text: 'Productos',
        value: 'productos',
        align: 'center',
        width: '5%'
      },
      {
        text: '# de Items Prestados',
        value: 'cantidadPrestados',
        align: 'center',
        width: '5%'
      },
      { text: 'Monto Total', value: 'costoVentaTotal', align: 'center' },
      { text: 'Fecha y Hora de Venta', value: 'fechaVenta', align: 'center' },
      {
        text: 'Monto al Contado',
        value: 'totalContado',
        align: 'center'
      },
      {
        text: 'Monto a Crédito',
        value: 'totalCredito',
        align: 'center'
      },
      { text: 'Saldo Deudor', value: 'saldoActual', align: 'center' },
      { text: '', value: 'data-table-expand' }
    ],
    headersListaProductos: [
      { text: 'Producto', value: 'nombreProducto', align: 'center' },
      { text: 'Costo/u', value: 'costoUnitario', align: 'center' },
      { text: 'Cantidad', value: 'cantidad', align: 'center' },
      { text: 'Costo Total', value: 'costoTotalProducto', align: 'center' }
    ],
    ventas: [],
    fechaTablaInicio: new Date(new Date().setHours(-5))
      .toISOString()
      .substr(0, 10),
    fechaTablaFin: new Date(new Date().setHours(24))
      .toISOString()
      .substr(0, 10),
    modalFechaTablaInicio: false,
    modalFechaTablaFin: false,
    buscarTabla: '',
    infoSnackbar: false,
    infoSnackbarMessage: '',
    nuevaVentaCreditoDialog: false,
    expandedLoading: false,
    rowSeleccionado: {},
    detalleVenta: {}
  }),
  methods: {
    getEstado(venta) {
      if (venta >= 300) return 'red'
      else if (venta >= 150) return 'yellow'
      else return 'green'
    },

    getForm(i) {
      this.$emit('change', i)
    },

    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },

    parseDate(date) {
      if (!date) return null
      const [day, month, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },

    formatDateTable(date) {
      let parsedDate = new Date(parseInt(date))
      let options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }
      return `${parsedDate.toLocaleDateString(
        'es-PE',
        options
      )} ${parsedDate.toLocaleTimeString()}`
    },

    cleanSource(source) {
      return JSON.parse(JSON.stringify(source))
    },

    async actualizarTabla() {
      let horaCeroInicio = new Date(this.fechaTablaInicio).setHours(24)
      let horaCeroFin = new Date(this.fechaTablaFin).setHours(24)
      //this.setError({ isError: false, error: "" });
      let fechas = { horaCeroInicio, horaCeroFin }
      this.ventas = await this.fetchVentasCredito(fechas)
      //console.log(this.ventas)
    },

    actualizarFechaInicio() {
      this.$refs.dialogFechaTablaInicio.save(this.fechaTablaInicio)
      this.actualizarTabla()
    },

    actualizarFechaFin() {
      this.$refs.dialogFechaTablaFin.save(this.fechaTablaFin)
      this.actualizarTabla()
    },

    async nuevaVentaActualizar(response) {
      this.nuevaVentaCreditoDialog = false
      this.infoSnackbar = true
      this.infoSnackbarMessage =
        'Se ha creado una nueva venta con ID ' + response
      await this.actualizarTabla()
    },

    exportToExcel() {
      let resource = this.cleanSource(this.ventas)

      for (let i = 0; i < resource.length; i++) {
        resource[i].fechaVenta = this.formatDateTable(resource[i].fechaVenta)
      }

      let dataExported = XLSX.utils.json_to_sheet(resource)
      dataExported['A1'].v = 'ID de Venta'
      dataExported['B1'].v = 'ID de Trabajador'
      dataExported['C1'].v = 'Nombre de Vendedor'
      dataExported['D1'].v = 'Cliente'
      dataExported['E1'].v = 'Productos'
      dataExported['F1'].v = 'Monto Total'
      dataExported['G1'].v = 'Fecha de Venta'
      dataExported['H1'].v = 'Monto al Contado'
      dataExported['I1'].v = 'Monto a Crédito'
      dataExported['J1'].v = 'Saldo Deudor'
      dataExported['K1'].v = '# de Items Vendidos'
      dataExported['L1'].v = '# de Items Prestados'

      const workbook = XLSX.utils.book_new()
      const filename = `${new Date().getTime()}-ventasCredito`
      XLSX.utils.book_append_sheet(workbook, dataExported, filename)
      XLSX.writeFile(workbook, `${filename}.xlsx`)
    },

    async whenExpanded(row) {
      if (row.value) {
        this.expandedLoading = true
        this.rowSeleccionado = row
        let response = await this.fetchDetalleVenta(row.item.idVenta)
        this.detalleVenta = response
        this.expandedLoading = false
      }
    },

    ...mapActions('user', ['fetchVentasCredito']),

    ...mapMutations('user', ['setError', 'loadingRequest']),

    ...mapActions('venta', ['fetchDetalleVenta'])
  },
  computed: {
    computedFechaFormateadaInicio() {
      return this.formatDate(this.fechaTablaInicio)
    },

    computedFechaFormateadaFin() {
      return this.formatDate(this.fechaTablaFin)
    },

    getFechaTabla() {
      const offsetDate = new Date(this.fechaTabla).setHours(
        new Date(this.fechaTabla).getHours() - 5
      )
      return new Date(offsetDate).toISOString().substr(0, 10)
    },

    getFechaFormateada() {
      return new Date(this.fechaTabla).toISOString().substr(0, 10)
    },

    getErrorMessage() {
      switch (this.getError.errorMessage) {
        case 'Network Error':
          return 'Error al intentar conectarse al servidor, contacte al administrador'
        default:
          return this.getError.errorMessage
      }
    },

    ...mapGetters('user', [
      'isError',
      'getError',
      'isLoading',
      'getInfoUsuario'
    ])
  },

  mounted: async function() {
    this.actualizarTabla()
  }
}
</script>