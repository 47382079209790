<template>
  <v-form ref="formVentaCredito" v-model="validVentaCreditoForm" lazy-validation>
    <v-card>
      <v-card-title class="green white--text">Formulario de Venta a Crédito</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div class="d-flex" :class="{ 'flex-column': $vuetify.breakpoint.smAndDown }">
          <div class="d-flex px-4 mb-4 mx-md-auto flex-grow-1">
            <v-autocomplete
              label="Cliente"
              v-model="venta.idCliente"
              class="mt-4"
              placeholder="Selecciona un Cliente"
              :items="getListaClientes"
              item-text="nombreCliente"
              item-value="idCliente"
              prepend-icon="mdi-briefcase-account"
              :rules="noVacioRules"
              outlined
              dense
              required
            >
              <template v-slot:item="data">
                <v-list-item-content>
                  <v-list-item-title v-text="data.item.nombreCliente"></v-list-item-title>
                  <v-list-item-subtitle v-text="data.item.direccion"></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </div>
        </div>
        <div class="d-flex flex-column">
          <div class="d-flex flex-row justify-space-between">
            <p class="title black--text font-weight-bold my-auto mx-4">Producto(s)</p>
            <v-btn dark color="base" class="mx-4" @click="agregarProducto">
              Agregar
              <v-icon right>mdi-plus-circle-outline</v-icon>
            </v-btn>
          </div>
          <div class="d-flex flex-column align-center mt-4">
            <template v-if="venta.productos.length">
              <v-sheet
                class="d-flex flex-column mt-2"
                color="grey lighten-4"
                v-for="(item, index) in venta.productos"
                :key="index"
              >
                <div class="d-flex justify-space-between">
                  <p class="font-weight-bold mx-4 mt-2 my-0">{{index+1}}.</p>
                  <v-btn text color="red" @click="eliminarProducto(index)">eliminar</v-btn>
                </div>
                <div class="d-flex mx-2 my-1">
                  <v-autocomplete
                    label="Producto"
                    placeholder="Selecciona un Producto"
                    v-model="venta.productos[index].idProducto"
                    class="flex-shrink-1 mx-2"
                    :items="getListaProductosFiltrada"
                    item-text="nombreProducto"
                    item-value="idProducto"
                    @change="definirPrecioxCliente(index)"
                    :rules="noVacioRules"
                    outlined
                    dense
                  ></v-autocomplete>
                  <v-text-field
                    class="mx-2 flex-grow-1"
                    label="Cantidad"
                    v-model="venta.productos[index].cantidad"
                    outlined
                    dense
                  ></v-text-field>
                </div>
                <div class="d-flex mx-2 my-1">
                  <v-text-field
                    class="mx-2 flex-shrink-1"
                    label="Precio/unidad"
                    prefix="S/"
                    v-model="venta.productos[index].costoUnitario"
                    :rules="cantidadDecimalRules"
                    outlined
                    dense
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="venta.productos[index].prestamoProducto"
                    color="base"
                    label="Item prestado"
                    class="flex-grow-1 mx-2"
                    outlined
                    dense
                    required
                    :rules="[
                          v => ((v || v == 0) && v !== '') || 'No puedes dejar el campo vacío',
                          v => ((v || v == 0) && parseInt(v) <= parseInt(venta.productos[index].cantidad) ) || 'No puedes prestar más de lo que se vende',
                          v=> ((v || v == 0) && parseInt(v) >= 0) || 'No puedes ingresar un número negativo',
                          v => ((v || v == 0) && v % 1 == 0) || 'Solo se admiten números enteros'
                        ]"
                  ></v-text-field>
                </div>
              </v-sheet>
            </template>
            <p
              v-else
              class="subtitle-1 text-center mt-4"
            >Aquí aparecen los productos agregados a la venta</p>
          </div>
        </div>
        <v-divider class="my-4"></v-divider>
        <div class="d-flex flex-column align-center">
          <div
            class="d-flex flex-row justify-center"
            :style="{width: $vuetify.breakpoint.mdAndUp ? '50%' : '75%'}"
          >
            <v-text-field
              class="ma-4 mt-2 flex-grow-0"
              label="Adelanto"
              prefix="S/"
              v-model="venta.totalContado"
              outlined
              dense
              style="max-width: 50%"
            ></v-text-field>
          </div>
          <div
            class="d-flex flex-column justify-center"
            :style="{width: $vuetify.breakpoint.mdAndUp ? '35%' : '75%'}"
          >
            <div class="d-flex flex-row justify-space-between">
              <p class="title font-weigth-bold black--text">Monto Total a Cobrar</p>
              <p class="title font-weigth-bold">{{ getMontoCobrar() }}</p>
            </div>

            <div class="d-flex flex-row justify-space-between">
              <p class="title font-weigth-bold black--text">Saldo deudor</p>
              <p class="title font-weigth-bold">{{ getSaldo() }}</p>
            </div>

            <div class="d-flex flex-row justify-space-between" v-if="tieneVuelto()">
              <p class="title font-weigth-bold black--text">Vuelto</p>
              <p class="title font-weigth-bold">{{ getVuelto() }}</p>
            </div>
          </div>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="ma-2 pb-4">
        <v-btn text :disabled="isLoading" @click="$emit('cerrarDialog')">Cerrar</v-btn>
        <transition name="slide-fade">
          <v-btn
            v-if="!validVentaCreditoForm"
            :disabled="isLoading"
            text
            class="my-0"
            @click="resetForm"
          >
            <v-icon left>mdi-refresh</v-icon>Limpiar
          </v-btn>
        </transition>
        <v-spacer></v-spacer>
        <v-btn color="green" :loading="isLoading" dark @click="submitFormCredito">Guardar</v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar :value="isError" color="error">
      {{ getError.error ? getError.errorMessage : '' }}
      <v-btn color="white" text @click="setError({ isError: false, error: null})">Cerrar</v-btn>
    </v-snackbar>
    <v-snackbar
      :value="infoSnackbar"
      color="success"
      :timeout="5000"
    >{{ infoSnackbar ? infoSnackbarMessage : '' }}</v-snackbar>
  </v-form>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  data: vm => ({
    modalFechaVenta: false,
    formTieneErrores: false,
    validVentaCreditoForm: true,
    pago: 0,
    adelanto: 0,
    montoCobrar: 0,
    saldo: 0,
    infoSnackbar: false,
    infoSnackbarMessage: '',
    noVacioRules: [v => !!v || 'Debes seleccionar un item de la lista'],
    cantidadDecimalRules: [
      v => (v && v > 0) || 'Debes insertar una cantidad mayor a 0'
    ],
    cantidadRules: [
      v => (v && v > 0) || 'Debes insertar una cantidad mayor a 0',
      v => (v && v % 1 == 0) || 'Solo se admiten números enteros'
    ],
    venta: {
      idCliente: '',
      costoVentaTotal: 0,
      fechaVenta: new Date().getTime(),
      esVentaCredito: true,
      productos: [],
      totalContado: 1,
      totalCredito: 0,
      saldoActual: 0
    },
    productos: [],
    clientes: []
  }),
  methods: {
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },

    parseDate(date) {
      if (!date) return null
      const [day, month, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },

    resetForm() {
      this.$refs.formVentaCredito.reset()
      this.venta = {
        idCliente: '',
        costoVentaTotal: 0,
        esVentaCredito: true,
        productos: [],
        totalContado: 0,
        totalCredito: 0,
        saldoActual: 0
      }
    },

    async submitFormCredito() {
      this.validVentaCreditoForm = false
      if (this.$refs.formVentaCredito.validate()) {
        let response = await this.crearVenta(this.cleanSource(this.venta))
        if (response) {
          // this.infoSnackbar = true;
          // this.infoSnackbarMessage =
          //   "Se ha creado una nueva venta con ID " + response;
          this.resetForm()
          await this.$emit('cerrarYActualizar', response)
        }
      }
    },

    agregarProducto() {
      this.venta.productos.push({
        idProducto: '',
        cantidad: 0,
        costoUnitario: 0,
        costoTotalProducto: 0,
        prestamoProducto: 0
      })
    },

    getMontoCobrar() {
      let monto = 0
      let costoTotalProducto
      if (this.venta.productos.length) {
        this.venta.productos.forEach(item => {
          costoTotalProducto = item.cantidad * item.costoUnitario
          monto += costoTotalProducto
          item.costoTotalProducto = costoTotalProducto
        })
        this.venta.costoVentaTotal = monto
      }

      return 'S/ ' + monto.toFixed(2)
    },

    getSaldo() {
      this.venta.totalCredito = this.venta.saldoActual = !this.tieneVuelto()
        ? this.venta.costoVentaTotal - this.venta.totalContado
        : 0
      if (!this.venta.totalContado) {
        this.venta.totalContado = 0
      } else {
        this.venta.totalContado = parseFloat(this.venta.totalContado)
      }
      return 'S/ ' + this.venta.totalCredito.toFixed(2)
    },

    tieneVuelto() {
      return this.venta.costoVentaTotal < this.venta.totalContado
    },

    getVuelto() {
      return (
        'S/ ' +
        (this.venta.totalContado - this.venta.costoVentaTotal).toFixed(2)
      )
    },

    eliminarProducto(index) {
      this.venta.productos.splice(index, 1)
    },

    cleanSource(source) {
      return JSON.parse(JSON.stringify(source))
    },

    definirPrecioxCliente(index) {
      if (this.venta.idCliente) {
        let clienteSeleccionado = this.getListaClientes.filter(
          i => i.idCliente == this.venta.idCliente
        )
        let montoSeleccionado = 0
        let idProdSeleccionado = this.venta.productos[index].idProducto
        let filteredProductos = this.getListaProductosFiltrada.filter(
          i => i.idProducto == idProdSeleccionado
        )
        let nombreProdSeleccionado = filteredProductos[0].nombreProducto
        if (nombreProdSeleccionado.indexOf('10k') >= 0) {
          montoSeleccionado = clienteSeleccionado[0].precio10k
            ? clienteSeleccionado[0].precio10k
            : 0
        } else if (nombreProdSeleccionado.indexOf('45k') >= 0) {
          montoSeleccionado = clienteSeleccionado[0].precio45k
            ? clienteSeleccionado[0].precio45k
            : 0
        }
        this.venta.productos[index].costoUnitario = montoSeleccionado
      }
    },

    ...mapActions('user', ['crearVenta']),
    ...mapMutations('user', ['setError', 'loadingRequest'])
  },
  computed: {
    computedFechaFormateada() {
      return this.formatDate(this.venta.fechaVenta)
    },

    getErrorMessage() {
      switch (this.getError.errorMessage) {
        case 'Network Error':
          return 'Error al intentar conectarse al servidor, contacte al administrador'
        default:
          return this.getError.errorMessage
      }
    },

    ...mapGetters('user', [
      'isError',
      'getError',
      'isLoading',
      'getListaProductosFiltrada',
      'getListaClientes'
    ])
  },

  mounted() {
    this.resetForm()
  }
}
</script>

<style>
.slide-fade-enter-active {
  transition: all 0.5s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
